<template>
  <div>
    <!--    <div v-show="singleRelease">-->
    <!--      <single-file-upload-->
    <!--        :change="(file, type) => onFileChange(file, type)"-->
    <!--        :file-info="ipa || apk"-->
    <!--        @reset="resetForm"-->
    <!--        :loading="!!(preLoadingAndroid || preLoadingIOS)"-->
    <!--        :progress="getIOSProgress || getAndroidProgress"-->
    <!--        @createRelease="submitRelease"-->
    <!--      ></single-file-upload>-->
    <!--    </div>-->
    <div>
      <!-- Button: How to use -->
      <v-tooltip
        bottom
        v-if="isExpress"
        transition="slide-y-reverse-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            small
            class="primary font-weight-bold"
            dark
            absolute
            v-bind="attrs"
            v-on="on"
            top
            right
            @click="startTour"
            style="z-index: 99"
          >
            ?
          </v-btn>
        </template>
        <span>How to use</span>
      </v-tooltip>
      <v-card-title class="mb-4 display-1 justify-center" v-if="!noHeader">
        New release
      </v-card-title>
      <!--  -->
      <!--  -->
      <!--  -->
      <div class="_releasetabnew">
        <div class="side-release">
          <v-form
            ref="android"
            class="formflex"
            @submit.prevent="() => submitRelease('android')"
          >
            <v-card
              class="
                transparent
                mt-lg-5
                flex-column
                justify-center
                align-center
              "
              tile
              flat
            >
              <div v-if="!isExpress">
                <div v-if="!appInfo">
                  <span class="red--text"> </span>
                  <AppAutoComplete
                    class="mb-4"
                    v-model="app"
                    :is-get-started="fromStarted"
                    :select-fist-item="fromStarted"
                    :focus.sync="focus"
                    @openApp="$emit('openApp')"
                    @input="app = $event"
                    @click.prevent="focusAppField(false)"
                    @blur="focus = false"
                    ref="app-autocomplete"
                  />
                </div>
                <h1 v-else class="text-center mb-4">
                  {{ appInfo.name }}
                </h1>
              </div>
              <v-row>
                <v-col
                  class="d-flex flex-column align-center"
                  :class="{ 'pr-5': $vuetify.breakpoint.smAndUp }"
                  cols="12"
                  md="6"
                  sm="6"
                >
                  <div
                    class="mx-auto"
                    :class="`platform-logo mb-5  ${
                      icon == 'android' ? 'selected-icon-container' : ''
                    }`"
                  >
                    <img
                      v-if="get(apk, 'info.icon', '')"
                      :src="get(apk, 'info.icon', '')"
                      @error="replaceDefaultAndroidImage"
                    />

                    <!-- <inline-svg
                      class="mb-8 svg-icon"
                      :max-height="100"
                      :max-width="100"
                      src="/media/placeholders/apk.svg"
                      v-if="!get(apk, 'info.icon', '')"
                    /> -->

                    <PlatformIcon
                      android
                      color="primary"
                      v-if="!get(apk, 'info.icon', '')"
                    />
                  </div>
                  <FileUploadDropzone
                    id="android-box"
                    ref="apk-upload"
                    :app_id="app.id"
                    :change="(file) => onFileChange(file, 'android')"
                    :fileInfo="apk"
                    :fromStarted="fromStarted"
                    :loading="preLoadingAndroid"
                    :is-express="isExpress"
                    :disabled="
                      ((Array.isArray(app) && app[0]) ||
                        !Object.keys(app).length ||
                        busy) &&
                      !isExpress
                    "
                    :onReleaseClick="onInfoClick"
                    :preError="preReleaseErrorAndroid"
                    :uploadStatus="{ ...getAndroidProgress }"
                    :releaseLoader="androidReleaseLoader"
                    @try-select="focusAppField(true)"
                    accept=".apk"
                    class="dropzonemain mx-auto"
                  />
                </v-col>
                <v-col
                  class="d-flex flex-column align-center"
                  :class="{ 'pr-5': $vuetify.breakpoint.smAndUp }"
                  cols="12"
                  md="6"
                  sm="6"
                >
                  <div
                    class="mx-auto"
                    :class="`platform-logo mb-5  ${
                      icon == 'ios' ? 'selected-icon-container' : ''
                    }`"
                  >
                    <img
                      v-if="get(ipa, 'info.icon', '')"
                      :src="get(ipa, 'info.icon', '')"
                      @error="replaceDefaultAndroidImage"
                    />

                    <PlatformIcon
                      apple
                      color="primary"
                      v-if="!get(ipa, 'info.icon', '')"
                    />
                  </div>
                  <FileUploadDropzone
                    id="ios-box"
                    ref="ipa-upload"
                    :app_id="app.id"
                    :change="(file) => onFileChange(file, 'ios')"
                    :fileInfo="ipa"
                    :is-express="isExpress"
                    :loading="preLoadingIOS"
                    :fromStarted="fromStarted"
                    :preError="preReleaseErrorIOS"
                    :disabled="
                      ((Array.isArray(app) && app[0]) ||
                        !Object.keys(app).length ||
                        busy) &&
                      !isExpress
                    "
                    :onReleaseClick="onInfoClick"
                    :uploadStatus="{ ...getIOSProgress }"
                    :releaseLoader="iosReleaseLoader"
                    @try-select="focusAppField(true)"
                    accept=".ipa"
                    class="dropzonemain mx-auto"
                  />
                </v-col>
              </v-row>

              <v-checkbox
                id="release-note-check-box"
                v-model="oneReleaseNote"
                label="One release note"
                hide-details
              ></v-checkbox>
              <div v-show="oneReleaseNote" class="my-3">
                <!-- :disabled="!apk && !ipa" -->
                <v-textarea
                  id="one-note"
                  v-validate="'max:600'"
                  v-bind="veeValidate('Description', 'Description')"
                  v-model="form.note"
                  :counter="600"
                  label="Note"
                  placeholder="What to expect or test for both releases?"
                  auto-grow
                  filled
                />
              </div>
              <v-row v-show="!oneReleaseNote">
                <v-col cols="12" sm="6">
                  <!-- :disabled="!apk" -->
                  <v-textarea
                    id="android-note"
                    v-validate="'max:600'"
                    v-bind="
                      veeValidate('Description-android', 'Android Description')
                    "
                    v-model="form.androidNote"
                    :counter="600"
                    label="Android note"
                    append-icon="android"
                    placeholder="What to expect or test in this release?"
                    auto-grow
                    filled
                  >
                    <template #append>
                      <PlatformIcon android color="grey" />
                    </template>
                  </v-textarea>
                </v-col>
                <v-col cols="12" sm="6">
                  <!-- :disabled="!ipa" -->
                  <v-textarea
                    id="ios-note"
                    v-validate="'max:600'"
                    v-bind="veeValidate('Description-ios', 'iOS Description')"
                    v-model="form.iosNote"
                    :counter="600"
                    label="iOS note"
                    placeholder="What to expect or test in this release?"
                    auto-grow
                    filled
                  >
                    <template #append>
                      <PlatformIcon apple color="grey" />
                    </template>
                  </v-textarea>
                </v-col>
              </v-row>
              <div v-if="!isExpress && !fromStarted" class="">
                <div class="tipFont">
                  <v-icon small v-text="'mdi-bell-outline'"></v-icon>
                  Notify
                  <VueTooltip
                    icon-color="grey"
                    color="primary"
                    text-class="white--text"
                    :v-html="'<ul><li>App members: Team members who have the access to this app will receive a push notification in our app for the relative platform.</li><br /><li>App subscribers: External users who had subscribed to receive updates for this app from the public install page. Check the Share page for more info.</li></ul>'"
                  />
                </div>
                <div class="ml-4">
                  <v-checkbox v-model="notify" hide-details>
                    <template #label>
                      <div>App members</div>
                    </template>
                  </v-checkbox>
                  <v-checkbox disabled hide-details>
                    <template #label>
                      <div>
                        App subscribers
                        <v-chip x-small class="ml-2">Soon</v-chip>
                      </div>
                    </template>
                  </v-checkbox>
                </div>
              </div>
              <div v-if="!isExpress && !fromStarted" class="my-8">
                <div class="tipFont">
                  <v-icon small>archive</v-icon>
                  Archive current latest release
                  <VueTooltip
                    icon-color="grey"
                    color="primary"
                    text-class="white--text"
                    :v-html="'Automatically archive the current latest release upon successful upload. <br /><br /> This will spare you from manually archiving the release.'"
                    :max-width="200"
                  />
                </div>
                <div class="ml-4">
                  <v-checkbox
                    v-model="archiveAndroid"
                    :disabled="!apk"
                    label="Android"
                    hide-details
                  ></v-checkbox>
                  <v-checkbox
                    v-model="archiveIos"
                    :disabled="!ipa"
                    label="iOS"
                    hide-details
                  ></v-checkbox>
                </div>
              </div>
              <v-checkbox
                v-if="!termsAndCondition"
                v-model="termsAndCondition"
                @click="setTermsAndCondition"
                id="terms-check-box"
              >
                <template #label>
                  <div>
                    By creating this release, I agree to
                    <a
                      @click.stop
                      href="https://testapp.io/terms-and-conditions"
                      target="_blank"
                    >
                      Terms & Conditions
                    </a>
                    and
                    <a
                      @click.stop
                      href="https://testapp.io/privacy-policy#upload"
                      target="_blank"
                    >
                      Upload Policy
                    </a>
                  </div>
                </template>
              </v-checkbox>

              <div class="mb-8">
                <v-icon left v-text="'mdi-lightbulb-on-outline'"></v-icon>
                <span class="tipFont">
                  Use our
                  <a href="https://github.com/testappio/cli" target="_blank"
                    >ta-cli integration</a
                  >
                  if you are used to terminal. It's easier and faster.
                </span>
              </div>
              <!-- button should be disabled in loading process... -->
              <div class="text-center">
                <v-btn
                  id="create-btn"
                  ref="submit_btn_release"
                  :disabled="
                    !app ||
                    (!apk && !ipa) ||
                    !agree ||
                    busy ||
                    preLoadingAndroid ||
                    preLoadingIOS
                  "
                  :loading="busy"
                  class="main large wide primary mx-auto my-3"
                  type="submit"
                  large
                >
                  create
                </v-btn>
                <v-btn
                  v-if="!noHeader && fromStarted"
                  ref="submit_btn_release"
                  class="main large wide primary mx-auto my-3"
                  @click="$router.push({ name: 'team' }).catch(() => {})"
                >
                  Skip
                </v-btn>
              </div>
            </v-card>
          </v-form>
        </div>
      </div>
      <Modal
        :width="$vuetify.breakpoint.mdAndUp ? 1100 : ''"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        @close="openSubscriptionModal = false"
        v-model="openSubscriptionModal"
      >
        <template #message>
          <plan-pricing
            from-modal
            :uploaded-size="uploadedSize"
            :allowed-size="allowedSize"
            @close="reset(), (openSubscriptionModal = false)"
          ></plan-pricing>
        </template>
      </Modal>
      <Modal v-model="expressLimitModal" @close="expressLimitModal = false">
        <template #message>
          <v-row class="bodyFont">
            <v-col class="bold-text mt-3" cols="12">
              Max app size limit reached
            </v-col>
            <v-col cols="12">
              <div class="mb-2">
                This release exceeded the limit which is
                <b>{{ allowedSize }}</b>
              </div>
              <div>
                You have uploaded <b>{{ uploadedSize }}</b>
              </div>
              <div class="bold-text text-center mt-4">
                Signup to increase the limit for free 💪
              </div>
            </v-col>
            <v-col class="text-center">
              <v-btn
                @click="$router.push({ name: 'signup' })"
                class="text-transform-none"
                color="primary"
              >
                Let's do it
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </Modal>
      <Modal
        v-model="showStartTrialModal"
        @close="showStartTrialModal = false"
        width="550"
      >
        <template #message>
          <start-trial-modal
            v-if="showStartTrialModal"
            :message="message"
            :uploaded-size="uploadedSize"
            :tracker-event="trackerEvent"
            :file-size="fileSize"
            :allowed-size="allowedSize"
            from-create-release
            @close="(showStartTrialModal = false), resetForm()"
            @open-subscribe="openSubscriptionModal = true"
          ></start-trial-modal>
        </template>
      </Modal>
      <side-panel
        v-model="showPreReleaseInfo"
        :width="$vuetify.breakpoint.mdAndUp ? 960 : ''"
        :fullscreen="$vuetify.breakpoint.smAndDown"
      >
        <pre-release-info-panel
          :is-express="isExpress"
          :app="app"
          :info="releaseInfo"
        />
      </side-panel>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { get, isEmpty } from "@/core/services/helper.service";
import { mapGetters, mapMutations, mapState } from "vuex";

import AppAutoComplete from "@/view/components/Common/AppAutoComplete.vue";
import FileUploadDropzone from "@/view/components/Common/FileUploadDropzone.vue";
import PreReleaseInfoPanel from "@/view/components/Releases/PreReleaseInfoPanel.vue";

import { UID } from "@/core/services/helper.service";
import AppInfoParser from "app-info-parser";
import {
  PRE_RELEASE,
  RELEASE,
  RELEASE_UPLOAD,
} from "@/store/releases/createRelease.module.js";
import { RELEASES } from "@/store/releases/releases.module.js";
import { CANCEL_UPLOAD } from "@/store/common/uploads.module";
import veeValidate from "@/mixins/veeValidate";
import * as Sentry from "@sentry/vue";
import StorageService from "@/core/services/localstorage.service";
import { formatBytes } from "@/core/services/helper.service";
import PlanPricing from "@/view/components/Team/PlanPricing";
// import socket from "@/store/common/socket-instance";
import dayjs from "dayjs";
import StartTrialModal from "@/view/components/Common/StartTrialModal";
// import SingleFileUpload from "@/view/components/Releases/SingleFileUpload";

export default {
  name: "create-release",
  props: {
    appInfo: {
      type: Object,
      default() {
        return null;
      },
    },
    singleRelease: {
      type: Boolean,
    },
    click: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    noHeader: {
      type: Boolean,
      default: false,
    },
    fromStarted: {
      type: Boolean,
      default: false,
    },
    isExpress: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [veeValidate],
  components: {
    StartTrialModal,
    // SingleFileUpload,
    PreReleaseInfoPanel,
    FileUploadDropzone,
    AppAutoComplete,
    PlanPricing,
  },
  data() {
    return {
      trackerEvent: {},
      get,
      formatBytes,
      isEmpty,
      checkbox: null,
      drawer: false,
      selected: null,
      app: {},
      form: {
        note: "",
        androidNote: "",
        iosNote: "",
      },
      apk: null,
      ipa: null,
      subscribe: false,
      apkUDID: null,
      showPreReleaseInfo: false,
      ipaUDID: null,
      showStartTrialModal: false,
      iosReleaseLoader: false,
      message: "",
      androidReleaseLoader: false,
      busy: false,
      focus: false,
      notify: true,
      archiveAndroid: false,
      archiveIos: false,
      agree: null,
      ids: {},
      openSubscriptionModal: false,
      expressLimitModal: false,
      apkFileToken: "",
      ipaFileToken: "",
      oneReleaseNote: false,
      icon: "",
      releaseInfo: null,
      preReleaseErrorIOS: "",
      preReleaseErrorAndroid: "",
      preLoadingIOS: false,
      preLoadingAndroid: false,
      forExpressAppId: "",
      allowedSize: "",
      uploadedSize: "",
      fileSize: "",
    };
  },
  watch: {
    // this is for navigation drawer to remove the scroll when it open
    drawer: function (v) {
      if (v) {
        // document.querySelector("body, html").style.overflow = "hidden";
      } else {
        // document.querySelector("body, html").style.overflow = "auto";
        // this.$emit("onClose");
      }
      this.app = {};
      if (this.appInfo) {
        this.app = { ...this.appInfo };
      }
      this.resetUpload();
      this.apk = null;
      this.ipa = null;
    },
    //

    oneReleaseNote: function (val) {
      if (val) {
        this.form.androidNote = "";
        this.form.iosNote = "";
      } else {
        this.form.note = "";
      }
    },
    appInfo: function (val) {
      if (val) {
        this.app = this.appInfo;
      }
    },
    bothProgress(val) {
      if (val.android && !val.ios && val.android.status === "uploaded") {
        this.makeAfterReleaseProgress();
      } else if (val.ios && !val.android && val.ios.status === "uploaded") {
        this.makeAfterReleaseProgress();
      } else if (
        val.ios &&
        val.android &&
        val.android.status === "uploaded" &&
        val.ios.status === "uploaded"
      ) {
        if ([val.android.status, val.ios.status].includes("uploaded")) {
          this.makeAfterReleaseProgress();
        }
      } else if (
        (val.ios && val.ios.status === "failed") ||
        (val.android && val.android.status === "failed")
      ) {
        this.busy = false;
        window.localStorage.setItem("is_uploading_releases", "false");
        this.setIsUploadingReleases(false);
      }
    },
    click: function (v) {
      this.drawer = v;
      this.resetForm();
    },
  },
  computed: {
    ...mapState({
      latest_releases: (state) => state.releases.latest_releases,
    }),
    termsAndCondition: {
      get: function () {
        return StorageService.getItem("release_terms_condition");
      },
      set: function (v) {
        StorageService.setItem("release_terms_condition", v);
      },
    },
    new_release_cli: {
      get: function () {
        return localStorage.getItem("info_message") &&
          JSON.parse(localStorage.getItem("info_message")).new_release_cli ===
            "false"
          ? false
          : true;
      },
      set: function (v) {
        localStorage.setItem(
          "info_message",
          JSON.stringify({
            ...JSON.parse(localStorage.getItem("info_message")),
            new_release_cli: v.toString(),
          })
        );
      },
    },
    ...mapGetters({
      getApkFile: "getApkFile",
      getIpaFile: "getIpaFile",
      getIOSProgress: "getIOSProgress",
      getAndroidProgress: "getAndroidProgress",
      expressReleaseToken: "expressReleaseToken",
      getTeamTrial: "getTeamTrial",
      getTeamId: "getTeamId",
      getStorageUsage: "getTeamPlanUsage",
      // isSocketDisconnected: "getIsSocketDisconnected"
    }),
    maxUploadSize() {
      return this.getStorageUsage.max_upload_size || 100000000;
    },
    bothProgress() {
      return { android: this.getAndroidProgress, ios: this.getIOSProgress };
    },
  },
  created() {
    if (this.termsAndCondition) {
      this.agree = true;
    }
  },
  mounted() {
    Vue.prototype.$analytics.logEvent("start_release");
    if (this.appInfo) {
      this.app = this.appInfo;
    }
  },
  methods: {
    ...mapMutations({
      setReleaseMessage: "setReleaseMessage",
      resetUpload: "resetUpload",
      appendAppStats: "appendAppStats",
      updateReleaseInfo: "updateReleaseInfo",
      setApkFileInfo: "setApkFileInfo",
      setIpaFileInfo: "setIpaFileInfo",
      setIsUploadingReleases: "setIsUploadingReleases",
      setReleaseImage: "setReleaseImage",
      setLatestRelease: "setLatestRelease",
      // setIsSocketDisconnected: "setIsSocketDisconnected"
    }),
    setTermsAndCondition() {
      if (StorageService.getItem("release_terms_condition")) {
        this.agree = true;
      } else {
        this.agree = false;
      }
    },

    startTour() {
      this.$tours["expressTour"].start();
    },
    focusAppField(val) {
      if (!(this.app && this.appInfo && !val)) {
        this.focus = true;
        if (this.$refs["app-autocomplete"]) {
          this.$refs["app-autocomplete"].openList();
        }
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    closeForm() {
      this.$emit("close");
      this.drawer = false;
      if (document.querySelector("body, html")) {
        document.querySelector("body, html").style.overflow = "auto";
      }
    },
    resetForm() {
      if (this.appInfo && !this.appInfo.name) {
        this.$refs["app-autocomplete"] &&
          this.$refs["app-autocomplete"].reset();
      }
      this.$refs["apk-upload"].reset();
      this.$refs["ipa-upload"].reset();
      this.$store.dispatch(CANCEL_UPLOAD, this.apkUDID);
      this.$store.dispatch(CANCEL_UPLOAD, this.ipaUDID);
      this.form = {
        note: "",
        androidNote: "",
        iosNote: "",
      };
      this.apk = null;
      this.ipa = null;
      this.notify = true;
      this.archiveAndroid = false;
      this.archiveIos = false;
      this.agree = null;
      this.apkUDID = null;
      this.ipaUDID = null;
      this.oneReleaseNote = false;
      this.icon = "";
    },
    async extractFileInfo(file, platform) {
      return new Promise((resolve, reject) => {
        // for Express max upload is always 50 MB
        const accept = platform == "android" ? ".apk" : ".ipa";
        let error = "";
        const maxUploadSize = this.isExpress ? 50000000 : this.maxUploadSize;
        const parser = new AppInfoParser(file);
        const info = {};
        parser
          .parse()
          .then((res) => {
            Vue.prototype.$analytics.logEvent("pre_release_start");
            let extension = file.name.split(".").pop();
            if (accept.split(",").indexOf(`.${extension}`) == -1) {
              window.analytics.track("Invalid file uploaded", {
                uploaded: extension,
                allowed: accept,
                is_express: this.isExpress,
              });
              error = `Please select a valid ${accept} file`;
            }
            if (platform === "android") {
              info.warnings = [];

              if (
                res &&
                res.application &&
                !res.application.label &&
                !Array.isArray(res.application.label)
              ) {
                info.name = this.appInfo ? this.appInfo.name : this.app.name;
                info.warnings.push(
                  "Your base manifest is missing android:label"
                );
              } else {
                info.name = res && res.application && res.application.label[0];
              }

              info.package = res.package;
              info.version =
                res && res.versionName ? res.versionName.toString() : "";
              info.version_code =
                res && res.versionCode ? res.versionCode.toString() : "";

              info.icon = res.icon;

              if (!info.icon) {
                info.warnings.push(
                  "Your base manifest is missing android:icon"
                );
              }

              info.meta = {};
              info.meta.usesPermissions = res.usesPermissions;

              info.meta.minSDKVersion =
                res.usesSdk && res.usesSdk.minSdkVersion
                  ? res.usesSdk.minSdkVersion.toString()
                  : "";
              info.meta.targetSDKVersion =
                res && res.usesSdk && res.usesSdk.targetSdkVersion
                  ? res.usesSdk.targetSdkVersion.toString()
                  : "";
            } else if (platform === "ios") {
              info.name = res.CFBundleDisplayName
                ? res.CFBundleDisplayName
                : res.CFBundleExecutable;
              info.package = res.CFBundleIdentifier;
              info.version = res.CFBundleShortVersionString;
              info.version_code = res.CFBundleVersion;
              info.icon = res.icon;

              info.customURL =
                res.CFBundleURLTypes && res.CFBundleURLTypes[0]
                  ? res.CFBundleURLTypes[0].CFBundleURLSchemes[0]
                  : "";
              info.meta = {};

              info.meta.minimumOSVersion =
                res && res.MinimumOSVersion
                  ? res.MinimumOSVersion.toString()
                  : "";
              info.meta.platformVersion =
                res && res.DTPlatformVersion
                  ? res.DTPlatformVersion.toString()
                  : "";
              info.meta.supportedPlatforms = res
                ? res.CFBundleSupportedPlatforms
                : "";

              info.meta.provisionedDevices = [];

              if (res.mobileProvision) {
                info.meta.provisionedDevices = res.mobileProvision
                  .ProvisionedDevices
                  ? res.mobileProvision.ProvisionedDevices
                  : [];
              }

              info.meta.UUID = res.mobileProvision
                ? res.mobileProvision.UUID
                : "";

              info.warnings = [];
              if (!res.mobileProvision || isEmpty(res.mobileProvision)) {
                //Store version
                info.error =
                  "It seems that this app has been signed with App Store distribution profile. Please upload either Development or Ad-Hoc IPA";
              } else if (
                !res.mobileProvision.ProvisionedDevices ||
                isEmpty(res.mobileProvision.ProvisionedDevices)
              ) {
                //Store version
                info.error =
                  "Please upload an app singed either with Development or Ad-Hoc provisioning profiles";
              } else if (!info.meta.UUID) {
                info.error =
                  "Please select an app singed either with Development or Ad-Hoc provisioning profiles";
              } else if (
                this.getDifference(res.mobileProvision.ExpirationDate)
              ) {
                info.error =
                  "The provisioning profile has expired.\n" +
                  "Please upload a new IPA with an active provisioning profile.";
              }
            }

            if (error || info.error) {
              window.analytics.track("Pre release error", {
                type: accept,
                is_express: this.isExpress,
                reason: info.error || error,
              });
            }
            if (!info.error && file.size > maxUploadSize) {
              error = `Max upload file size of ${
                this.isExpress ? "express" : "your team's plan"
              } is
            ${formatBytes(maxUploadSize / 1000)} and you selected ${formatBytes(
                file.size / 1000
              )} ${
                this.isExpress
                  ? "Signup for more."
                  : "Check Team Plans page for more info."
              }`;
              this.message = `Max file size for your team is
            ${formatBytes(maxUploadSize / 1000)} and you selected ${formatBytes(
                file.size / 1000
              )}`;
              window.analytics.track("Max size uploaded", {
                type: accept,
                is_express: this.isExpress,
                allowed_size: this.formatBytes(maxUploadSize / 1000),
                uploaded_size: this.formatBytes(file.size / 1000),
              });
              this.allowedSize = this.formatBytes(maxUploadSize / 1000);
              this.uploadedSize = this.formatBytes(file.size / 1000);
              this.fileSize = file.size / 1000;
              this.trackerEvent = {
                event: "Max upload size",
                current: this.allowedSize,
                requested: this.uploadedSize,
              };
              if (!this.isExpress) {
                if (isEmpty(this.getTeamTrial) && !this.isExpress) {
                  this.showStartTrialModal = true;
                } else if (!isEmpty(this.getTeamTrial && !this.isExpress)) {
                  const popup = StorageService.getItem("popup");
                  if ((!popup || !popup.subscription) && !info.error) {
                    this.openSubscriptionModal = true;
                  }
                }
              } else {
                this.expressLimitModal = true;
              }
            }

            info.file = {};
            info.file.name = file.name;
            info.file.extension = file.name.split(".").pop();
            info.file.size = file.size;

            Vue.prototype.$analytics.logEvent("pre_release");
            if (error || info.error) {
              reject({ message: info.error || error });
            } else {
              resolve(info);
            }
          })
          .catch((err) => {
            Sentry.captureMessage(err);
            Vue.prototype.$analytics.logEvent("pre_release_error");
            reject(err);
          });
      });
    },
    getDifference(date) {
      const currentDate = dayjs().format();
      return new Date(currentDate) > new Date(date);
    },
    async submitRelease() {
      this.resetUpload();
      if (this.busy) return;
      this.busy = true;
      this.setIsUploadingReleases(true);
      window.localStorage.setItem("is_uploading_releases", "true");

      const body = {
        notify: this.notify,
      };
      if (this.apk) {
        body.android = {
          id: this.apk.id,
          note: this.oneReleaseNote ? this.form.note : this.form.androidNote,
          icon: this.icon === "android",
          archive_latest_release: !!this.archiveAndroid,
        };
        this.androidReleaseLoader = true;
      }
      if (this.ipa) {
        body.ios = {
          id: this.ipa.id,
          note: this.oneReleaseNote ? this.form.note : this.form.iosNote,
          archive_latest_release: !!this.archiveIos,
        };
        this.iosReleaseLoader = true;
      }

      Vue.prototype.$analytics.logEvent("create_release_start");

      this.$store
        .dispatch(RELEASE, {
          isExpress: this.isExpress,
          app_id: this.app.id,
          body,
        })
        .then((response) => {
          this.forExpressAppId = response.id;
          this.uploadRelease(response);
          this.androidReleaseLoader = false;
          this.iosReleaseLoader = false;
          Vue.prototype.$analytics.logEvent("create_release");
        })
        .catch((err) => {
          this.androidReleaseLoader = false;
          this.iosReleaseLoader = false;
          this.notifyErrorMessage(err.message || "Unable to create release!");
          this.busy = false;

          Vue.prototype.$analytics.logEvent("create_release_error");
        });
    },
    uploadRelease(details) {
      Vue.prototype.$analytics.logEvent("upload_release_start");
      this.ids = details;
      this.$store
        .dispatch(RELEASE_UPLOAD, {
          ipa: {
            id: this.ipaUDID,
            type: "ios",
            token: this.ipaFileToken,
            action: this.isExpress ? "express" : "release",
            url: details.ios ? details.ios.url : "",
          },
          apk: {
            id: this.apkUDID,
            type: "android",
            token: this.apkFileToken,
            action: this.isExpress ? "express" : "release",
            url: details.android ? details.android.url : "",
          },
        })
        .then(() => {
          Vue.prototype.$analytics.logEvent("upload_release");
          if (this.appInfo) {
            this.setReleaseMessage(details.message);
          } else {
            this.setReleaseMessage(details.message);
          }
        })
        .catch((err) => {
          Vue.prototype.$analytics.logEvent("upload_release_error");
          this.notifyErrorMessage(err.message || "Unable to create release!");
          this.busy = false;
        });
    },
    makeAfterReleaseProgress() {
      this.setIsUploadingReleases(false);
      this.$emit("socketReconnect");
      window.localStorage.setItem("is_uploading_releases", "false");
      const imageDetails = {
        iosId: this.ids && this.ids.ios ? this.ids.ios.id : null,
        androidId: this.ids && this.ids.android ? this.ids.android.id : null,
        iosIcon: this.ipa && this.ipa.info ? this.ipa.info.icon : null,
        androidIcon: this.apk && this.apk.info ? this.apk.info.icon : null,
      };
      if (this.isExpress) {
        this.resetUpload();
        let data = {};
        if (this.apk && this.ipa) {
          data = {
            iosId: this.ids.ios.id,
            androidId: this.ids.android.id,
            both: true,
            ipa: this.ipa,
            apk: this.apk,
          };
        } else if (this.apk) {
          data = {
            platform: "android",
            expReleaseID: this.ids.android.id,
            ...this.apk,
          };
        } else {
          data = {
            platform: "ios",
            expReleaseID: this.ids.ios.id,
            ...this.ipa,
          };
        }
        this.$emit("success", data);
        this.busy = false;
        if (!this.singleRelease) {
          this.notifyUserMessage({
            message: "Release successfully created",
            timeout: true,
          });
        }
        return;
      }
      const popup = JSON.parse(localStorage.getItem("popup"));
      let box = popup ? popup.install_instruction : null;

      if (this.appInfo) {
        this.$store
          .dispatch(RELEASES, { app_id: this.appInfo.id })
          .then(() => {
            this.closeForm();
            const latestReleases = this.latest_releases.map((release) => {
              if (release.id === imageDetails.androidId) {
                release.image = imageDetails.androidIcon;
              }
              if (release.id === imageDetails.iosId) {
                release.image = imageDetails.iosIcon;
              }
              this.$emit("released");
              return release;
            });
            this.setLatestRelease(latestReleases);
          })
          .catch((err) => {
            this.notifyErrorMessage(err.message);
          });
      } else {
        this.setReleaseImage(imageDetails);
        if (!box) {
          localStorage.setItem(
            "popup",
            JSON.stringify({
              ...JSON.parse(localStorage.getItem("popup")),
              install_instruction: "false",
            })
          );
          // box = JSON.parse(localStorage.getItem("popup"));
        }
        if (!this.singleRelease) {
          this.notifyUserMessage("Release successfully uploaded");
        }
        if (box !== "true") {
          this.$router
            .push({
              name: "app-releases",
              params: {
                app_id: this.app.id,
              },
              query: {
                install: true,
              },
            })
            .catch(() => {});
        } else {
          this.$router
            .push({
              name: "app-releases",
              params: {
                app_id: this.app.id,
              },
            })
            .catch(() => {});
        }
      }
      const apkSize = this.apk && this.apk.file ? this.apk.file.size / 1000 : 0;
      const ipaSize = this.ipa && this.ipa.file ? this.ipa.file.size / 1000 : 0;
      this.updateReleaseInfo({
        count: this.apk && this.ipa ? 2 : 1,
        size: apkSize + ipaSize,
      });
      if (box !== "true") {
        if (this.apk && this.ipa) {
          this.$emit("releaseSuccess");
        } else {
          this.$emit("releaseSuccess", this.apk ? this.apk : this.ipa);
        }
      }
      this.appendAppStats({
        releaseCount: this.apk && this.ipa ? 2 : 1,
        storage:
          this.app && this.app.storage && this.app.storage.name === "TestApp.io"
            ? apkSize + ipaSize
            : null,
        externalStorage:
          this.app && this.app.storage && this.app.storage.name !== "TestApp.io"
            ? apkSize + ipaSize
            : null,
      });
      this.closeForm();
      this.busy = false;
      this.$emit("released");
      if (!this.singleRelease) {
        this.notifyUserMessage({
          message: "Release successfully created",
          timeout: true,
        });
      }
    },
    async onFileChange(file, platform) {
      if (!file && !platform) {
        this.apk = null;
        this.ipa = null;
      }
      this.androidUploadStatus = {};
      this.iosUploadStatus = {};
      if (!file) {
        platform === "android" && ((this.apk = null), (this.apkUDID = null));
        platform === "ios" && ((this.ipa = null), (this.ipaUDID = null));
      } else {
        const udid = UID();
        platform === "android" && (this.apkUDID = udid);
        platform === "ios" && (this.ipaUDID = udid);
        if (platform === "android") {
          this.preLoadingAndroid = true;
          this.setApkFileInfo(file);
          this.preReleaseErrorAndroid = "";
        }
        if (platform === "ios") {
          this.setIpaFileInfo(file);
          this.preLoadingIOS = true;
          this.preReleaseErrorIOS = "";
        }
        await this.extractFileInfo(file, platform)
          .then((extractRes) => {
            Vue.prototype.$analytics.logEvent("extract_release_start");
            return this.$store
              .dispatch(PRE_RELEASE, {
                isExpress: this.isExpress,
                id: this.app.id,
                body: {
                  platform: platform,
                  info: extractRes,
                },
              })
              .then((res) => {
                if (platform === "android") {
                  this.preLoadingAndroid = false;
                  this.apkUDID = res.id;
                  this.apkFileToken = res.id;
                  this.apk = {
                    ...this.apk,
                    id: res.id,
                    info: extractRes,
                    meta: res.meta,
                    file: file,
                  };
                }
                if (platform === "ios") {
                  this.preLoadingIOS = false;
                  this.ipaUDID = res.id;
                  this.ipaFileToken = res.id;
                  this.ipa = {
                    ...this.ipa,
                    id: res.id,
                    info: extractRes,
                    token: res.token,
                    meta: res.meta,
                    file: file,
                  };
                }
                Vue.prototype.$analytics.logEvent("extract_release");
              })
              .catch((err) => {
                Vue.prototype.$analytics.logEvent("extract_release_error");

                window.analytics.track("Pre release", {
                  platform: platform,
                  message: err.message,
                  step: "api",
                  status: "failed",
                });

                if (platform === "android") {
                  this.preReleaseErrorAndroid =
                    err.message || "Something went wrong, please try again";
                }
                if (platform === "ios") {
                  this.preReleaseErrorIOS =
                    err.message || "Something went wrong, please try again";
                }
              });
          })
          .catch((err) => {
            Vue.prototype.$analytics.logEvent("pre_release_error");
            window.analytics.track("Pre release", {
              platform: platform,
              message: err,
              file: file,
              step: "extract",
              status: "failed",
            });
            if (platform === "android") {
              this.preReleaseErrorAndroid =
                err.message || "Please select a valid APK file";
            }
            if (platform === "ios") {
              this.preReleaseErrorIOS =
                err.message || "Please select a valid IPA file";
            }
          });
        if (platform === "android") {
          this.preLoadingAndroid = false;
        }
        if (platform === "ios") {
          this.preLoadingIOS = false;
        }
      }
    },

    replaceDefaultAndroidImage(e) {
      e.target.src = "/media/defaults/icon-120.png";
    },
    replaceDefaultIOSImage(e) {
      e.target.src = "/media/defaults/icon-120.png";
    },
    onInfoClick(info) {
      this.releaseInfo = info;
      delete this.releaseInfo.file;
      this.showPreReleaseInfo = true;
      Vue.prototype.$analytics.logEvent("release_info");
    },
    onConfirm() {
      this.resetForm();
    },
  },
};
</script>
