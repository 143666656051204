<template>
  <div class="release-info">
    <div>
      <div class="text-center">
        <v-card-title class="display-1 justify-center mb-5">Info</v-card-title>
        <div class="text-muted font-weight-bold">
          <h2>
            {{
              get(info, "info.file.extension", "") == "apk" ? "Android" : "iOS"
            }}
          </h2>
        </div>
      </div>
      <v-expansion-panels multiple v-model="topPanel" flat>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="headline">
              {{
                get(info, "info.file.extension", "") == "apk" ? "APK" : "IPA"
              }}
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="text-muted px-6">
              <div>App Name: {{ get(app, "name", "") }}</div>
              <div>Package: {{ get(info, "info.package", "") }}</div>
              <div>Version: {{ get(info, "info.version", "") }}</div>
              <div>
                Version Code:
                {{ get(info, "info.version_code", "") }}
              </div>

              <div v-if="get(info, 'info.file.extension', '') == 'apk'">
                Target SDK:
                {{ get(info, "info.meta.targetSDKVersion", "") }}
              </div>
              <div v-if="get(info, 'info.file.extension', '') == 'apk'">
                Min SDK:
                {{ get(info, "info.meta.minSDKVersion", "") }}
              </div>
              <div v-if="get(info, 'info.file.extension', '') == 'ipa'">
                Platform Version:
                {{ get(info, "info.meta.platformVersion", "") }}
              </div>
              <div v-if="get(info, 'info.file.extension', '') == 'ipa'">
                Min OS:
                {{ get(info, "info.meta.minimumOSVersion", "") }}
              </div>
              <div>
                Size:
                {{ formatBytes(get(info, "info.file.size", 0) / 1000) }}
              </div>
              <div>File name: {{ get(info, "info.file.name", "") }}</div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="headline">Permission</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              class="text-muted pl-6"
              :class="
                get(info, 'info.meta.usesPermissions', []).length
                  ? 'scrollBar'
                  : ''
              "
            >
              <div
                v-for="(permission, i) of get(
                  info,
                  'info.meta.usesPermissions',
                  []
                )"
                v-bind:key="i"
              >
                <div>{{ permission.name }}</div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row v-if="!isExpress" class="ml-3">
        <v-col cols="12" md="6">
          <div class="headline black--text">Members</div>
          <div class="text-muted">
            <div>
              Total eligible: {{ get(info, "meta.members.eligible", 0) }}
            </div>
            <div v-if="get(info, 'info.file.extension', '') == 'apk'">
              Total with Android:
              {{ get(info, "meta.members.eligible_per_platform", 0) }}
            </div>
            <div v-if="get(info, 'info.file.extension', '') == 'ipa'">
              Total with iOS:
              {{ get(info, "meta.members.eligible_per_platform", 0) }}
            </div>
            <div>
              Total with Android & iOS:
              {{ get(info, "meta.members.total", 0) }}
            </div>
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="headline black--text">Devices</div>
          <div class="text-muted">
            <div>Total: {{ get(info, "meta.devices.total", 0) }}</div>
            <div>
              Total eligible:
              {{ get(info, "meta.devices.total_eligible", 0) }}
            </div>
            <div>
              Total not eligible:
              {{ get(info, "meta.devices.total_not_eligible", 0) }}
            </div>
          </div>
        </v-col>
      </v-row>
      <v-expansion-panels class="mb-5" v-model="warning" flat>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <div class="headline black--text">Warnings</div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <template
              v-if="
                !get(info, 'meta.devices.members', []).length &&
                !(info && info.info.warnings && info.info.warnings.length)
              "
            >
              <div
                class="d-flex flex-center text-center text-muted min-h-200px"
              >
                <div class="no-data-found mx-auto">
                  <inline-svg
                    class="mt-6 svg-icon empty_icon"
                    src="/media/placeholders/check.svg"
                  />
                  <p class="mt-2 empty-title bold-text text-dark text-center">
                    All looks good
                  </p>
                </div>
              </div>
            </template>
            <template v-else>
              <v-expansion-panels
                v-if="info && info.info.warnings && info.info.warnings.length"
              >
                <v-expansion-panel class="mb-2">
                  <v-expansion-panel-header>
                    <v-row>
                      <v-col cols="auto">
                        <span class="title">
                          {{
                            get(info, "info.file.extension", "") == "apk"
                              ? "APK"
                              : "IPA"
                          }}
                          warnings
                        </span>
                        <v-chip
                          small
                          color="orange"
                          class="font-weight-bold"
                          v-text="info.info.warnings.length"
                        >
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content class="pa-0">
                    <v-card flat tile class="primary-light pa-4 mb-2">
                      <ul>
                        <li v-for="(warning, j) in info.info.warnings" :key="j">
                          {{ warning }}
                        </li>
                      </ul>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <b
                v-if="
                  get(info, 'meta.devices.members', []).length && !isExpress
                "
                >Devices</b
              >
              <v-expansion-panels
                v-if="info && info.meta && info.meta.devices && !isExpress"
              >
                <v-expansion-panel
                  outline
                  v-for="(member, i) in get(info, 'meta.devices.members', [])"
                  v-bind:key="i"
                >
                  <v-expansion-panel-header>
                    <v-row align="center">
                      <v-col cols="auto">
                        <img
                          v-if="info.info.icon"
                          :src="info.info.icon"
                          height="50"
                        />
                        <img
                          v-else
                          src="/media/defaults/icon-120.png"
                          height="50"
                        />
                      </v-col>
                      <v-col class="text-left">
                        <div class="black--text">{{ member.name }}</div>
                        <div class="text-muted mr-4 mt-2">
                          {{ member.devices.length }} devices are not eligible
                        </div>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card
                      v-for="(device, j) of member.devices"
                      :key="j"
                      flat
                      tile
                      class="primary-light pa-4 mb-2"
                    >
                      <div>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                            class="py-0 d-flex justify-space-between"
                          >
                            <span class="bold-text mr-2">Name:</span>
                            {{ device.info.name || "---" }}
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                            class="py-0 d-flex justify-space-between"
                          >
                            <span class="bold-text mr-2">Type:</span>
                            {{ device.info.type || "---" }}
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                            class="py-0 d-flex justify-space-between"
                          >
                            <span class="bold-text mr-2">Model:</span>
                            {{ device.info.model || "---" }}
                          </v-col>

                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                            class="py-0 d-flex justify-space-between"
                          >
                            <span class="bold-text mr-2">Language:</span>
                            {{ device.info.language || "---" }}
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                            class="py-0 d-flex justify-space-between"
                          >
                            <span class="bold-text mr-2">Manufacturer:</span>
                            {{ device.info.manufacturer || "---" }}
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                            class="py-0 d-flex justify-space-between"
                          >
                            <span class="bold-text mr-2">Region:</span>
                            {{ device.info.region || "---" }}
                          </v-col>

                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                            class="py-0 d-flex justify-space-between"
                          >
                            <span class="bold-text mr-2"
                              >Notifications Enabled:</span
                            >
                            {{
                              device.info.app.push_enabled
                                ? "Yes"
                                : "No" || "---"
                            }}
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                            class="py-0 d-flex justify-space-between"
                          >
                            <span class="bold-text mr-2">SDK version:</span>
                            {{ device.info.sdk_version || "---" }}
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="4"
                            class="py-0 d-flex justify-space-between"
                          >
                            <span class="bold-text mr-2">OS version:</span>
                            {{ device.info.os_version || "---" }}
                          </v-col>
                        </v-row>

                        <div class="mr-2 mt-3" v-if="device.os == 'ios'">
                          <span class="bold-text">UDID:</span>
                          {{ device.udid || "---" }}
                        </div>

                        <div class="bold-text mr-2 mt-3">Messages</div>
                        <div
                          v-for="(message, k) of device.messages"
                          v-bind:key="k"
                        >
                          <div class="mr-2 mt-3">
                            <b>{{ k + 1 }}</b
                            >. {{ message }}
                          </div>
                        </div>
                      </div>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>

<script>
import { get, isEmpty } from "lodash";
import { formatBytes } from "@/core/services/helper.service";

export default {
  name: "pre-release-info",
  props: ["info", "app", "data", "isExpress"],
  data() {
    return {
      get,
      formatBytes,
      warning: 0,
      topPanel: [0, 0],
    };
  },
  computed: {
    noData() {
      return isEmpty(this.data) ? true : false;
    },
  },
  methods: {
    replaceDefaultImage(e) {
      e.target.src = "/media/defaults/icon-120.png";
    },
  },
};
</script>

<style lang="scss">
.scrollBar {
  overflow-y: scroll;
  max-height: 200px;
}
.scrollBar::-webkit-scrollbar {
  width: 3px;
}
.release-info {
  .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }
}
</style>
